import React from "react"
import { Divider, Container } from "@material-ui/core"

const CustomDivider = ({ extendLeft, extendRight }) => {
  return (
    <Container
      style={{
        paddingLeft: extendLeft && 0,
        paddingRight: extendRight && 0,
      }}
    >
      <Divider />
    </Container>
  )
}

export default CustomDivider
