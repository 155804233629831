import React from "react"
import Img from "gatsby-image"
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    boxShadow: "0 0 5px rgba(1,2,4,0.04)",
    display: "flex",
    flexDirection: "column",
  },
  cardImg: {
    height: 174,
  },
  cardContent: {
    padding: theme.spacing(1),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  overline: {
    fontFamily: "MabryProLight",
  },
}))

const TourExampleCard = ({ cardData }) => {
  const {
    itineraryTitle,
    itineraryDescription,
    noOfCities,
    timeDuration,
    tourImage,
    tourPdf,
  } = cardData
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardMedia>
        <Img fluid={tourImage.fluid} className={classes.cardImg} />
      </CardMedia>
      <div className={classes.cardContent}>
        <CardContent>
          <Typography variant="overline" className={classes.overline}>
            {noOfCities} • {timeDuration}
          </Typography>
          <Typography variant="h6">{itineraryTitle}</Typography>
          <Typography>{itineraryDescription.itineraryDescription}</Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            href={`https:${tourPdf?.file?.url}`}
            endIcon={<VerticalAlignBottomIcon />}
          >
            Download PDF
          </Button>
        </CardActions>
      </div>
    </Card>
  )
}

export default TourExampleCard
