import React from "react"
import { Element } from "react-scroll"
import { Grid, Typography, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom"
import EventImagesGrid from "../EventImagesGrid"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(15),
    },
  },
  title: {
    paddingTop: theme.spacing(1.5),
  },
  servicesContainer: {
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(15),
    },
  },
  servicesSubtitle: {
    paddingTop: theme.spacing(1),
    color: theme.palette.primary.light,
  },
  servicesItem: {
    marginTop: theme.spacing(3),
    paddingInline: 0,
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingInlineStart: "16px",
    },
    "& li": {
      paddingBottom: theme.spacing(1.25),
      listStyleType: "square",
    },
    "& li::marker": {
      color: "rgba(1,2,4,0.2)",
    },
  },
  button: {
    marginTop: theme.spacing(1),
  },
}))

const DmcEventSection = ({
  title,
  description,
  pdfUrl,
  translations,
  eventImages,
  servicesList,
}) => {
  const classes = useStyles()

  return (
    <Element name="eventSolutions" className="element">
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} md={4}>
          <Typography variant="overline">services</Typography>
          <Typography
            variant="h2"
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html: title?.childMarkdownRemark?.html,
            }}
          />
          <Typography
            dangerouslySetInnerHTML={{
              __html: description?.childMarkdownRemark?.html,
            }}
          />
          <Button
            variant="outlined"
            color="secondary"
            href={`https:${pdfUrl}`}
            endIcon={<VerticalAlignBottomIcon />}
            className={classes.button}
          >
            {translations?.downloadPdf}
          </Button>
        </Grid>
        <Grid item xs={12} md={8}>
          <EventImagesGrid eventImages={eventImages} />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.servicesContainer}>
        {servicesList.map(({ title, subTitle, list }, index) => {
          return (
            <Grid item md={4} xs={12} key={index}>
              <Typography variant="overline" color="secondary">
                {(index + 1).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
              </Typography>

              <Typography variant="h6" component="h3">
                {title}
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.servicesSubtitle}
              >
                {subTitle}
              </Typography>
              <ul className={classes.servicesItem}>
                {list.map(listItem => {
                  return <li key={listItem}>{listItem}</li>
                })}
              </ul>
            </Grid>
          )
        })}
      </Grid>
    </Element>
  )
}

export default DmcEventSection
