import React from "react"
import { Grid } from "@material-ui/core"
import "./index.scss"
import Img from "gatsby-image"

const EventImagesGrid = ({ eventImages }) => {
  return (
    <Grid container spacing={2} className="event-grid-images">
      <Grid item xs={7} md={5} className="first-grid">
        <div className="imageWrapper_1">
          <Img fluid={eventImages?.[0]?.fluid} className="first-image" />
        </div>
        <div className="imageWrapper_2">
          <Img fluid={eventImages?.[1]?.fluid} className="second-image" />
        </div>
      </Grid>
      <Grid item xs={5} md={4} className="second-grid">
        <div className="imageWrapper_3">
          <Img fluid={eventImages?.[2]?.fluid} className="third-image" />
        </div>
        <div className="imageWrapper_4">
          <Img fluid={eventImages?.[3]?.fluid} className="fourth-image" />
        </div>
      </Grid>
      <Grid item md={3} className="third-grid">
        <div className="imageWrapper_5">
          <Img fluid={eventImages?.[4]?.fluid} className="five-image" />
        </div>
        <div className="imageWrapper_6">
          <Img fluid={eventImages?.[5]?.fluid} className="six-image" />
        </div>
      </Grid>
    </Grid>
  )
}

export default EventImagesGrid
