import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CarouselSection from "../CarouselSection"
import TourExampleCard from "./TourExampleCard"
import useRackSize from "../useRackSize"

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    flexBasis: "25%",
    padding: theme.spacing(1.5),
  },
}))

const TourExamples = ({ cards, title, description }) => {
  const cardsData = cards.map(card => {
    return { node: card }
  })

  const classes = useStyles()

  const rackSize = useRackSize()
  return (
    <CarouselSection
      title={title}
      description={description}
      cardsData={cardsData}
      rackSize={rackSize}
    >
      {cardsData.map(({ node }, index) => (
        <Box
          key={index}
          className={classes.cardWrapper}
          style={{ minWidth: `${100 / rackSize}%` }}
        >
          <TourExampleCard cardData={node} />
        </Box>
      ))}
    </CarouselSection>
  )
}

export default TourExamples
