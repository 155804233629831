import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ActivitiesCard from "./ActivitiesCard"
import useRackSize from "../useRackSize"
import CarouselSection from "../CarouselSection"

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    flexBasis: "25%",
    padding: theme.spacing(1.5),
  },
}))

const ActivitiesCarousel = ({ activityCards, description, title }) => {
  const classes = useStyles()

  const cardsData = activityCards.slice(0, 4).map(node => ({ node }))
  const rackSize = useRackSize()
  const Description3 = description.replace(/<[^>]+>/g, "")

  return (
    <CarouselSection
      title={title}
      description={Description3}
      cardsData={cardsData}
      indexBtn={{ label: "View All", path: "/Destinations" }}
      rackSize={rackSize}
    >
      {cardsData.map(({ node }) => {
        return (
          <Box
            key={node.slug}
            className={classes.cardWrapper}
            style={{ minWidth: `${100 / rackSize}%` }}
          >
            <ActivitiesCard destination={node} /* btnLabel={btnLabel} */ />
          </Box>
        )
      })}
    </CarouselSection>
  )
}

export default ActivitiesCarousel
