import React from "react"
import Img from "gatsby-image"
import { Element } from "react-scroll"
import { Grid, Typography, Button, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom"
import DmcGroupImagesGrid from "../DmcGroupImagesGrid/index"

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(15),
    },
  },
  title: {
    paddingTop: theme.spacing(1.5),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  servicesContainer: {
    paddingTop: theme.spacing(5),
  },
  servicesRow: {
    paddingBottom: theme.spacing(3),
  },
}))

const DmcSection = ({
  title,
  description,
  pdf,
  gridImages,
  servicesList,
  translations,
}) => {
  const classes = useStyles()

  return (
    <Element name="dmc" className="element">
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} md={6}>
          <DmcGroupImagesGrid dmcImages={gridImages} />
        </Grid>
        <Grid item md={1} />
        <Grid item xs={12} md={4} className={classes.column}>
          <Typography variant="overline">{translations?.services}</Typography>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: description?.childMarkdownRemark?.html,
            }}
          />
          <Button
            variant="outlined"
            color="secondary"
            href={`https:${pdf?.file?.url}`}
            endIcon={<VerticalAlignBottomIcon />}
            className={classes.button}
          >
            {translations?.downloadPdf}
          </Button>

          <div className={classes.servicesContainer}>
            {servicesList.map(subList => {
              return (
                <Grid
                  container
                  key={subList.title}
                  spacing={3}
                  className={classes.servicesRow}
                >
                  {subList.map(({ title, image }) => {
                    return (
                      <Grid key={title} item xs={6}>
                        <Box width={40} pb={1.5}>
                          <Img fluid={image?.fluid} />
                        </Box>
                        <Typography variant="body2">{title}</Typography>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            })}
          </div>
        </Grid>
      </Grid>
    </Element>
  )
}

export default DmcSection
