import React from "react"
import Img from "gatsby-image"
import { Hidden, Grid, Typography, Box, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import CircleChecked from "@material-ui/icons/CheckCircleOutline"

const useStyles = makeStyles(theme => ({
  titleSection: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  pageTitle: {
    textAlign: "center",
    fontSize: "3rem",
    letterSpacing: "-0.24px",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2.5),
      fontSize: "5rem",
      letterSpacing: "-0.31px",
    },
  },
  pageSubtitle: {
    textAlign: "center",
    fontSize: "1rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  },
  flagsWrapper: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  titleFlags: {
    paddingLeft: theme.spacing(1),
    fontFamily: "MabryProRegular",
    lineHeight: "1.25",
    fontSize: "1.2rem",
  },
  imageRight: {
    left: "100px",
    width: "80%",
    height: "100%",
  },
}))

const DmcTitleSection = ({
  imgLeft,
  imgRight,
  title,
  subtitle,
  flags,
  isSmall,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={classes.titleSection}>
      <Grid container>
        <Grid item xs={3} md={3}>
        {!isSmall && (
            <Img fluid={imgLeft} />
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="h1" className={classes.pageTitle}>
            {title}
          </Typography>
          <Typography
            variant="subtitle"
            className={classes.pageSubtitle}
            dangerouslySetInnerHTML={{
              __html: subtitle?.childMarkdownRemark?.html,
            }}
          />
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              md={8}
              container
              justify="space-between"
              className={classes.flagsWrapper}
            >
              {flags.map(flag => {
                return (
                  <Box
                    key={flag}
                    display="flex"
                    width={smDown ? 1 : null}
                    justifyContent="center"
                    pb={2}
                  >
                    <CircleChecked color="secondary" fontSize="small" />
                    <Typography className={classes.titleFlags}>
                      {flag}
                    </Typography>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} md={3}>
          {!isSmall && (
            <Img fluid={imgRight} className={classes.imageRight} />
          )}
        </Grid>
      </Grid>
      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={6}>
            <Img
              fluid={imgLeft}
              imgStyle={{
                width: "unset",
                height: "100%",
                maxHeight: "457px",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Img
              fluid={imgRight}
              imgStyle={{
                width: "unset",
                height: "80%",
                maxHeight: "457px",
                left: "unset",
                right: "0",
              }}
            />
          </Grid>
        </Grid>
      </Hidden>
    </div>
  )
}

export default DmcTitleSection
