import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {
  Box,
  Snackbar,
  withWidth,
  Card,
  CardContent,
  CardActionArea,
  Divider,
  Grid,
  Typography,
  Container,
  Hidden,
} from "@material-ui/core"
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom"
import Alert from "@material-ui/lab/Alert"
import { useSelector } from "react-redux"
import { Link } from "react-scroll"
import { Destinations } from "../components/Destinations/Destinations"
import DmcQuery from "../components/DmcQuery"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import { dmcPageListFormatter } from "../utils"
import TourExamples from "../components/TourExamples/TourExamples"
import ActivitiesCarousel from "../components/ActivitiesCarousel"
import DmcTitleSection from "../components/Dmc/DmcTitleSection"
import DmcEventSection from "../components/Dmc/DmcEventSection"
import DmcSection from "../components/Dmc/DmcSection"
import DmcDelegationSection from "../components/Dmc/DmcDelegationSection"
import zhutravelTheme from "../components/Layout/zhutravelTheme"
import { SEO } from "../components/SEO"

export const query = graphql`
  query($locale: String!) {
    allContentfulDmcPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          topSectionPageTitle
          topSectionDescription {
            childMarkdownRemark {
              html
            }
          }
          topSectionFlags
          eventCardTitle
          eventCardDescription {
            childMarkdownRemark {
              html
            }
          }
          eventCardLogo {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
            title
          }
          dmcCardTitle
          dmcCardDescription {
            childMarkdownRemark {
              html
            }
          }
          dmcCardLogo {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
            title
          }
          delegationCardTitle
          delegationCardDescription {
            childMarkdownRemark {
              html
            }
          }
          delegationCardLogo {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
            title
          }
          eventSectionTitle {
            childMarkdownRemark {
              html
            }
          }

          eventSectionDescription {
            childMarkdownRemark {
              html
            }
          }
          eventSectionGridImages {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          eventSectionServicesList {
            title
            subTitle
            list
          }
          eventSectionPdf {
            file {
              url
            }
          }
          dmcSectionTitle
          dmcSectionDescription {
            childMarkdownRemark {
              html
            }
          }
          dmcSectionGridImages {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          dmcSectionServicesList
          dmcSectionServicesImages {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
            title
          }
          dmcSectionPdf {
            file {
              url
            }
          }
          delegationSectionTitle
          delegationSectionDescription {
            childMarkdownRemark {
              html
            }
          }
          delegationSectionGridImages {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          delegationSectionServicesList
          delegationSectionServicesImages {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
            title
          }
          delegationSectionPdf {
            file {
              url
            }
          }

          destinationSectionTitle
          destinationSectionDescription {
            childMarkdownRemark {
              html
            }
          }
          activityCarouselTitle
          activityCarouselDescription {
            childMarkdownRemark {
              html
            }
          }
          tourExampleCarouselTitle
          tourExampleCarouselDescription {
            tourExampleCarouselDescription
          }
          partnersLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          trustedByLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          activityCards {
            activityName
            activityImage {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
            activityDescription {
              activityDescription
            }
            mainDescription {
              childMarkdownRemark {
                html
              }
            }
          }
          tourExampleCards {
            noOfCities
            timeDuration
            itineraryTitle
            itineraryDescription {
              itineraryDescription
            }
            tourPdf {
              file {
                url
              }
            }
            tourImage {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
          }
          queryDescription {
            childMarkdownRemark {
              html
            }
          }
          firstQueryTopicTitle
          firstQueryTopics
          secondQueryTopicTitle
          secondQueryTopics
          thirdQueryTopicTitle
          thirdQueryTopics
          fourthQueryTopicTitle
          fourthQueryTopics
        }
      }
    }

    allContentfulDmcPageSeo(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          seoTitle
          seoDescription
          generalText {
            content {
              content {
                nodeType
                value
              }
              nodeType
            }
          }
        }
      }
    }

    allContentfulCityModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          slug
          cityName
          countryFlag {
            fluid(maxWidth: 20) {
              ...GatsbyContentfulFluid
            }
          }
          cardTruncateDescription {
            cardTruncateDescription
          }
          bannerImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    headerImgLeft: file(name: { eq: "Header_DMC-1" }) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 640) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImgRight: file(name: { eq: "Header_DMC-2" }) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 640) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const dmcTheme = createMuiTheme(zhutravelTheme, {
  palette: {
    secondary: {
      main: "#177C80",
    },
    primary: {
      light: "rgba(1,2,4,0.6)",
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontFamily: "MabryProRegular",
      },
    },
    MuiFormControl: {
      root: {
        marginTop: zhutravelTheme.spacing(3.5),
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#177C80",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#177C80",
      },
    },
  },
  props: {
    MuiInputLabel: {
      shrink: true,
    },
    MuiTextField: {
      fullWidth: true,
    },
  },
})

const useStyles = makeStyles(theme => ({
  media: {
    height: 600,
  },
  card: {
    height: 400,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  serviceCard: {
    boxShadow: "0 0 5px rgba(1,2,4, 0.2)",
  },
  serviceCardContent: {
    padding: theme.spacing(3),
  },
  serviceTitle: {
    fontFamily: "MabryProRegular",
    fontSize: "1.25rem",
    paddingTop: theme.spacing(2),
  },
  serviceSubtitle: {
    "& p": {
      margin: 0,
    },
  },
  h3Style: {
    textAlign: "center",
    marginTop: "10vh",
    color: "#010204",
    fontFamily: "Clearface",
    fontSize: "60px",
    letterSpacing: "-0.31px",
    marginBottom: "24px",
  },
  h6Style: {
    textAlign: "center",
    color: "#010204",
    fontFamily: "Mabry Pro",
    fontSize: "24px",
    fontWeight: "300",
    width: "36%",
    margin: "auto",
    marginBottom: "40px",
    lineHeight: "32px",
  },
  dflex: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
  },
  labeltext: {
    fontFamily: "MabryProLight",
  },

  input: {
    fontFamily: "MabryProRegular",
  },
  label: {
    "&$focusedLabel": {
      color: "black",
    },
    "&$erroredLabel": {
      color: "black",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  underline: {
    "&$error:after": {
      borderBottomColor: "black",
    },
    "&:after": {
      borderBottom: `2px solid black`,
    },
  },
  anchors: {
    marginTop: "10vh",

    "@media (max-height: 1500px)": {
      marginTop: "-8vh",
    },
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "rgba(1,2,4,0.1)",
      color: "#177C80",
    },
    transition: "all 0.3s ease",
  },
}))

const DmcScreen = props => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const classes = useStyles()
  const [error, setError] = React.useState("")
  const [success, setSuccess] = React.useState("")
  const { width } = props
  const isSmall = width === "xs" || width === "sm"
  const {
    topSectionPageTitle,
    topSectionDescription,
    topSectionFlags,
    eventCardTitle,
    eventCardDescription,
    eventCardLogo,
    eventSectionServicesList,
    dmcCardTitle,
    dmcCardDescription,
    dmcCardLogo,
    delegationCardTitle,
    delegationCardDescription,
    delegationCardLogo,
    delegationSectionPdf,
    eventSectionTitle,
    eventSectionDescription,
    eventSectionGridImages,
    eventSectionPdf,
    dmcSectionTitle,
    dmcSectionDescription,
    dmcSectionGridImages,
    dmcSectionServicesList,
    dmcSectionServicesImages,
    dmcSectionPdf,
    delegationSectionTitle,
    delegationSectionDescription,
    delegationSectionGridImages,
    delegationSectionServicesList,
    delegationSectionServicesImages,
    activityCarouselTitle,
    activityCarouselDescription,
    tourExampleCarouselTitle,
    tourExampleCarouselDescription,
    partnersLogos,
    trustedByLogos,
    tourExampleCards,
    activityCards,
    firstQueryTopicTitle,
    firstQueryTopics,
    secondQueryTopicTitle,
    secondQueryTopics,
    thirdQueryTopicTitle,
    thirdQueryTopics,
    fourthQueryTopicTitle,
    fourthQueryTopics,
    queryDescription,
  } = props.data.allContentfulDmcPageModel.edges[0].node

  const {
    seoTitle,
    seoDescription,
    generalText,
  } = props.data.allContentfulDmcPageSeo.edges[0].node

  const dmcServicesList = dmcPageListFormatter(
    dmcSectionServicesList,
    dmcSectionServicesImages
  )
  const delegationServicesList = dmcPageListFormatter(
    delegationSectionServicesList,
    delegationSectionServicesImages
  )

  const serviceCardsData = [
    {
      title: eventCardTitle,
      description: eventCardDescription,
      logo: eventCardLogo,
      link: "eventSolutions",
    },
    {
      title: dmcCardTitle,
      description: dmcCardDescription,
      logo: dmcCardLogo,
      link: "dmc",
    },
    {
      title: delegationCardTitle,
      description: delegationCardDescription,
      logo: delegationCardLogo,
      link: "delegations",
    },
  ]
  const headerImgLeft = props.data.headerImgLeft?.childImageSharp?.fluid
  const headerImgRight = props.data.headerImgRight?.childImageSharp?.fluid

  return (
    <Layout
      selected={"dmc"}
      button={"#177C80"}
      buttontextcolor={"white"}
      location={props.location}
      pageContext={props.pageContext}
    >
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <div
        className={locale === "zh" && "chinese-version"}
        style={{ overflowX: "hidden" }}
      >
        <ThemeProvider theme={dmcTheme}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={success.length}
            autoHideDuration={6000}
            onClose={() => setSuccess("")}
          >
            <Alert onClose={() => setSuccess("")} severity="success">
              {success}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={error.length}
            autoHideDuration={6000}
            onClose={() => setError("")}
          >
            <Alert onClose={() => setError("")} severity="error">
              {error}
            </Alert>
          </Snackbar>

          <DmcTitleSection
            imgLeft={headerImgLeft}
            imgRight={headerImgRight}
            title={topSectionPageTitle}
            subtitle={topSectionDescription}
            flags={topSectionFlags}
            isSmall={isSmall}
          />
          <Container
            {...(isSmall
              ? {
                  style: {
                    marginTop: "-100px",
                  },
                }
              : {
                  className: classes.anchors,
                })}
          >
            <Grid container spacing={2} justify="center">
              {serviceCardsData.map(service => (
                <Grid key={service.title} item xs={12} sm={6} md={4}>
                  <Link
                    to={service.link}
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    delay={10}
                  >
                    <Card className={classes.serviceCard}>
                      <CardActionArea>
                        <CardContent className={classes.serviceCardContent}>
                          <Box width={40}>
                            <Img fluid={service.logo?.fluid} />
                          </Box>
                          <Typography
                            variant="h3"
                            className={classes.serviceTitle}
                          >
                            {service.title}
                          </Typography>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="start"
                            pt={1}
                          >
                            <Typography
                              variant="subtitle1"
                              className={classes.serviceSubtitle}
                              dangerouslySetInnerHTML={{
                                __html:
                                  service.description?.childMarkdownRemark
                                    ?.html,
                              }}
                            />
                            <div className={classes.arrow}>
                              <ArrowDownwardIcon fontSize="small" />
                            </div>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Container>

          <Container>
            <DmcEventSection
              title={eventSectionTitle}
              description={eventSectionDescription}
              pdfUrl={eventSectionPdf?.file?.url}
              translations={translations}
              eventImages={eventSectionGridImages}
              servicesList={eventSectionServicesList}
            />
          </Container>

          <Hidden smDown>
            <Container>
              <Divider />
            </Container>
          </Hidden>

          <Container>
            <DmcSection
              title={dmcSectionTitle}
              description={dmcSectionDescription}
              pdf={dmcSectionPdf}
              gridImages={dmcSectionGridImages}
              servicesList={dmcServicesList}
              translations={translations}
            />
          </Container>

          <Hidden smDown>
            <Container>
              <Divider />
            </Container>
          </Hidden>

          <Container>
            <DmcDelegationSection
              translations={translations}
              title={delegationSectionTitle}
              description={delegationSectionDescription}
              pdf={delegationSectionPdf}
              servicesList={delegationServicesList}
              gridImages={delegationSectionGridImages}
            />
          </Container>

          <Container>
            <Divider />
          </Container>

          <Destinations locale={props.pageContext.locale} />

          <Container>
            <Divider />
          </Container>

          <ActivitiesCarousel
            activityCards={activityCards}
            title={activityCarouselTitle}
            description={activityCarouselDescription?.childMarkdownRemark?.html
              ?.split("\n")
              ?.join("<br/>")}
          />

          <Container>
            <Divider />
          </Container>

          <TourExamples
            cards={tourExampleCards}
            title={tourExampleCarouselTitle}
            icons={<VerticalAlignBottomIcon />}
            description={
              tourExampleCarouselDescription?.tourExampleCarouselDescription
            }
            buttonText={"Download PDF"}
          />

          <Container>
            <Divider />
          </Container>

          <Container>
            <DmcQuery
              isSmall={isSmall}
              firstQueryTopicTitle={firstQueryTopicTitle}
              firstQueryTopics={firstQueryTopics}
              secondQueryTopicTitle={secondQueryTopicTitle}
              secondQueryTopics={secondQueryTopics}
              thirdQueryTopicTitle={thirdQueryTopicTitle}
              thirdQueryTopics={thirdQueryTopics}
              fourthQueryTopicTitle={fourthQueryTopicTitle}
              fourthQueryTopics={fourthQueryTopics}
              queryDescription={queryDescription}
            />
          </Container>
          <SEO
            seoData={{
              generalText: generalText,
            }}
            isGenText
          />
          <Container>
            <Divider />
          </Container>

          <Footer
            trustedByLogos={trustedByLogos}
            partnersLogos={partnersLogos}
          />
        </ThemeProvider>
      </div>
    </Layout>
  )
}

export default withWidth()(DmcScreen)
