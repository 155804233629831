import React, { useState } from "react"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core"
import ReactCardFlip from "react-card-flip"

const imgHeight = 200

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardImg: {
    height: imgHeight,
  },
  cardContent: {
    padding: theme.spacing(1),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inline: {
    display: "inline-block",
  },
  flipText: {
    height: imgHeight,
  },
}))

const DestinationCard = ({ destination }) => {
  const classes = useStyles()
  const {
    activityImage,
    activityDescription,
    activityName,
    mainDescription,
  } = destination

  const [isFlipped, setFlip] = useState(false)

  return (
    <Card
      variant="outlined"
      className={classes.root}
      onMouseEnter={() => setFlip(true)}
      onMouseLeave={() => setFlip(false)}
    >
      <CardActionArea>
        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
          <CardMedia>
            <Img fluid={activityImage.fluid} className={classes.cardImg} />
          </CardMedia>
          <CardMedia>
            <CardContent
              className={classes.flipText}
              dangerouslySetInnerHTML={{
                __html: mainDescription.childMarkdownRemark.html,
              }}
            />
          </CardMedia>
        </ReactCardFlip>
        <div className={classes.cardContent}>
          <CardContent>
            <Typography variant="h6" className={classes.inline}>
              {activityName}
            </Typography>
            <Typography>{activityDescription.activityDescription}</Typography>
          </CardContent>
        </div>
      </CardActionArea>
    </Card>
  )
}

export default DestinationCard
