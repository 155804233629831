import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import DateFnsUtils from "@date-io/date-fns"
import {
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  CircularProgress,
  Snackbar,
  useMediaQuery,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ArrowForward from "@material-ui/icons/ArrowForward"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import Axios from "axios"
import { useSelector } from "react-redux"
import { BASE_URL } from "../../config/api"
import { AlertTitle, Alert } from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(15),
    },
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(15),
    },
  },
  h3: {
    fontFamily: "MabryProRegular",
    fontSize: "1rem",
    marginTop: theme.spacing(6),
  },
  topicsTitle: {
    fontSize: "0.875rem",
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(6),
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
}))
const DmcQuery = ({
  firstQueryTopicTitle,
  firstQueryTopics,
  secondQueryTopicTitle,
  secondQueryTopics,
  thirdQueryTopicTitle,
  thirdQueryTopics,
  fourthQueryTopicTitle,
  fourthQueryTopics,
  queryDescription,
}) => {
  const [customMessage, setCustomMessage] = useState("")
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const [alert, setAlert] = useState({
    severity: "error",
    message: "Something went wrong",
  })
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = React.useState([])
  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()))
  const [fullName, setFullName] = React.useState("")
  const [email, setSEmil] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [groupMember, setGroupMember] = useState("")
  const [loading, setLoading] = React.useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "HelloDmc" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const handlechange = name => event => {
    let newValue = selectedValue
    if (event.target.checked) {
      newValue.push(name)
      setSelectedValue(newValue)
    } else {
      let index = newValue.indexOf(name)
      newValue.splice(index, 1)
      setSelectedValue(newValue)
    }
  }

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const getEmailBody = () => `
    <div>
      <p>
        <strong>Name:</strong> ${fullName}
      </p>
      <p>
        <strong>Email:</strong> ${email}
      </p>
      <p>
        <strong>Phone:</strong> ${phone}
      </p>
      ${groupMember &&
        `<p>
        <strong>Group members:</strong> ${groupMember}
      </p>`}
    ${selectedDate &&
      `
    <p>
      <strong>Date:</strong> ${selectedDate}
    </p>`}
    ${selectedValue.length > 0 &&
      `
    <p>
      <strong>Solutions</strong> <ul>${selectedValue.map(
        solution => `<li>${solution}</li>`
      )}</ul>
    </p>`}
    ${customMessage &&
      `
    <p>
      <strong>Other details:</strong> ${customMessage}
    </p>`}

    </div>
  `

  const handleSend = event => {
    event.preventDefault()
    const emailBody = getEmailBody()
    const body = {
      subject: "Customer Enquiry",
      html: emailBody,
    }
    try {
      setLoading(true)
      Axios.post(BASE_URL + "/email", body).then(response => {
        setIsOpen(true)
        setAlert({ severity: "success", message: response.data.message })
      })
    } catch (err) {
      setAlert({ severity: "success", message: err })
      setIsOpen(true)
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const solutionsOptions = [
    { title: firstQueryTopicTitle, topics: firstQueryTopics },
    { title: secondQueryTopicTitle, topics: secondQueryTopics },
    { title: thirdQueryTopicTitle, topics: thirdQueryTopics },
    { title: fourthQueryTopicTitle, topics: fourthQueryTopics },
  ]
  const closeAlert = () => {
    setIsOpen(false)
    setAlert({
      severity: "error",
      message: "Something went wrong",
    })
  }
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert severity={alert.severity}>
          <AlertTitle>{alert.severity.toUpperCase()}</AlertTitle>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} wrap="wrap-reverse" className={classes.root}>
        <Grid item xs={12} md={6}>
          <Img fluid={data.file.childImageSharp.fluid} />
        </Grid>
        <Grid item md={1} />
        <Grid item xs={12} md={4}>
          <Typography variant="h2">{translations?.sendEnquiry}</Typography>
          <Typography
            variant="subtitle1"
            dangerouslySetInnerHTML={{
              __html: queryDescription?.childMarkdownRemark?.html,
            }}
          />
          <Typography variant="h3" className={classes.h3}>
            {translations?.personalInformation}
          </Typography>
          <form onSubmit={handleSend}>
            <TextField
              label={translations?.fullName}
              placeholder={translations?.typeFullName}
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              required
            />
            <TextField
              required
              type="email"
              label={translations?.emailAddress}
              placeholder={translations?.typeEmail}
              value={email}
              onChange={e => setSEmil(e.target.value)}
            />
            <TextField
              required
              label={translations?.phone}
              placeholder={translations?.typeNumber}
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
            <Typography variant="h3" className={classes.h3}>
              Solutions
            </Typography>
            <TextField
              label={translations?.noOfPeople}
              placeholder={translations?.typeNo}
              value={groupMember}
              onChange={e => setGroupMember(e.target.value)}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                clearable
                variant={mdUp ? "inline" : "dialog"}
                value={selectedDate}
                placeholder="10/10/2018"
                onChange={date => handleDateChange(date)}
                minDate={new Date()}
                format="yyyy-MM-dd"
                label={translations?.estimateDate}
              />
            </MuiPickersUtilsProvider>
            <Grid container>
              {solutionsOptions.map(({ title, topics }) => (
                <Grid item xs={6} key={title}>
                  <FormControl>
                    <FormLabel className={classes.topicsTitle}>
                      {title}
                    </FormLabel>
                    <FormGroup>
                      {topics.map(topic => (
                        <FormControlLabel
                          key={topic}
                          control={
                            <Checkbox
                              value={selectedValue.includes(topic)}
                              onChange={handlechange(topic)}
                            />
                          }
                          label={topic}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            <Typography variant="h3" className={classes.h3}>
              Other details
            </Typography>
            <TextField
              placeholder="Type your custom request here (Optional)"
              value={customMessage}
              onChange={e => setCustomMessage(e.target.value)}
            />

            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              endIcon={
                loading ? <CircularProgress size={20} /> : <div className={classes.arrow}><ArrowForward /></div>
              }
              disabled={loading}
              type={"submit"}
            >
              Send Message
            </Button>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default DmcQuery
