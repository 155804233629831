import React from "react"
import { Grid } from "@material-ui/core"
import "./index.scss"
import Img from "gatsby-image"

const DmcGroupImagesGrid = ({ dmcImages }) => {
  return (
    <Grid container spacing={2} className="dmc-images-grid">
      <Grid item xs={7} md={7} className="first-grid">
        <div className="imageWrapper_1">
          <Img fluid={dmcImages[0].fluid} className="img" />
        </div>
        <div className="imageWrapper_2">
          <Img fluid={dmcImages[1].fluid} className="img" />
        </div>
      </Grid>
      <Grid item xs={5} md={5} className="second-grid">
        <div className="imageWrapper_3">
          <Img fluid={dmcImages[2].fluid} className="img" />
        </div>
        <div className="imageWrapper_4">
          <Img fluid={dmcImages[3].fluid} className="img" />
        </div>
      </Grid>
    </Grid>
  )
}

export default DmcGroupImagesGrid
