import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {
  Container,
  Grid,
  Box,
  Typography,
  List,
  ListItemText,
  ListItem,
  ListSubheader,
  useMediaQuery,
  Hidden,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import LocalizedLink from "../LocalizedLink"
import Zigzag from "../Zigzag"
import CustomDivider from "../CustomDivider"

const useStyles = makeStyles(theme => ({
  logoSection: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
    },
  },
  navSubheader: {
    textTransform: "uppercase",
    fontSize: "0.75rem",
    letterSpacing: "0.5px",
  },
  zhutravel: {
    fontFamily: "MabryProBold",
    fontSize: "1.5rem",
  },
  sectionTitle: {
    fontFamily: "MabryProLight",
    fontSize: "0.875rem",
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}))

const LogosBox = ({ title, logos, mdUp, nPerRow }) => {
  // Define horizontal padding of each logo
  const px = { smDown: 1.5, mdUp: 3 }

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Typography variant="h6" className={useStyles().sectionTitle}>
        {title}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={mdUp ? "flex-start" : "center"}
        flexGrow={1}
        pt={mdUp ? 0 : 2.5}
        flexWrap="wrap"
      >
        {logos.map(({ fluid }, i) => (
          <Box
            key={i}
            width={mdUp ? 1 / nPerRow.mdUp : 1 / nPerRow.smDown}
            pl={i === 0 ? 0 : mdUp ? px.mdUp : px.smDown}
            pr={
              i >= logos.length - 1
                ? 0
                : !mdUp && i === nPerRow.smDown - 1
                ? 0
                : mdUp
                ? px.mdUp
                : px.smDown
            }
            mt={i >= nPerRow.smDown && !mdUp ? 3 : 0}
          >
            <Img fluid={fluid} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const Footer = ({ hidePartners }) => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      allContentfulLandingPageModel {
        edges {
          node {
            trustedByLogos {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            partnersLogos {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
      allContentfulCityModel(filter: { showInFooter: { eq: true } }) {
        edges {
          node {
            slug
            cityName
            node_locale
          }
        }
      }

      en: file(name: { eq: "logo-zhutravel-en-cropped" }) {
        childImageSharp {
          fixed(height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      zh: file(name: { eq: "logo-zhutravel-zh-cropped" }) {
        childImageSharp {
          fixed(height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      enWt: file(name: { eq: "logo-zhutravel-en-cropped-white" }) {
        childImageSharp {
          fixed(height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      zhWt: file(name: { eq: "logo-zhutravel-zh-cropped-white" }) {
        childImageSharp {
          fixed(height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const path = typeof window !== "undefined" && window.location.pathname.split("/")[1]
  const darkPages = ["invest", "WhyPortugal", "GoldenVisa"]
  const [darkSelected, setDarkSelected] = useState(false)

  useEffect(() => {
    console.log(path)
    setDarkSelected(darkPages.includes(path) ? true : false)
  }, [darkPages, path])

  const classes = useStyles()

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const locale = useSelector(state => state?.locale?.localeObj?.locale)

  const localeList = data.allContentfulCityModel.edges.filter(
    ({ node }) => node.node_locale === locale
  )

  // Convert cities list into correct array format
  const citiesList = localeList.map(({ node }) => ({
    label: node.cityName,
    path: `/destinations/${node.slug}`,
  }))

  const {
    trustedByLogos,
    partnersLogos,
  } = data.allContentfulLandingPageModel.edges[0].node

  // Define links for footer navigation section
  const footerNav = [
    {
      title: translations?.travel,
      links: [
        { label: translations?.privateTour, path: "/MapScreen" },
        { label: translations?.createCustomTour, path: "/MapScreen" },
        { label: translations?.dmcAndIncoming, path: "/dmc" },
        { label: translations?.iAmTourLeader, path: "/" },
      ],
    },
    {
      title: translations?.cities,
      links: citiesList,
    },
    {
      title: translations?.invest,
      links: [
        { label: translations?.services, path: "/invest" },
        { label: translations?.investPages?.goldenVisa, path: "/GoldenVisa" },
        { label: translations?.investPages?.whyPortugal, path: "/WhyPortugal" },
      ],
    },
    {
      title: translations?.header?.menu?.about,
      links: [
        { label: translations?.header?.menu?.about, path: "/about" },
        { label: translations?.blogs, path: "/blog" },
        { label: translations?.header?.contact, path: "/contact" },
      ],
    },
    {
      title: translations?.social,
      links: [
        {
          label: translations?.facebook,
          path: "https://www.facebook.com/zhutravel",
          external: true,
        },
        {
          label: translations?.instagram,
          path: "https://www.instagram.com/zhu.travel/",
          external: true,
        },
        {
          label: translations?.tripadvisor,
          path:
            "https://www.tripadvisor.com/Attraction_Review-g189180-d12707834-Reviews-Zhutravel-Porto_Porto_District_Northern_Portugal.html",
          external: true,
        },
      ],
    },
  ]

  return (
    <>
      <Container>
        {!hidePartners && (
          <>
            <Grid
              container
              spacing={3}
              justify="center"
              className={classes.logoSection}
            >
              <Grid item xs={12} md={3}>
                <LogosBox
                  logos={trustedByLogos}
                  mdUp={mdUp}
                  title={translations?.trustedBy}
                  nPerRow={{ smDown: 3, mdUp: 2 }}
                />
              </Grid>

              <Grid item xs={12} md={1} />

              <Grid item xs={12} md={8}>
                <LogosBox
                  logos={partnersLogos}
                  mdUp={mdUp}
                  title={translations?.partners}
                  nPerRow={{ smDown: 3, mdUp: 5 }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
      <CustomDivider extendRight />
      <Container>
        <Box id="footerNav" py={10}>
          <Grid container>
            <Grid item xs={12} sm={6} md={2}>
              <LocalizedLink to="/">
                <Img
                  fixed={
                    darkSelected
                      ? locale === "zh"
                        ? data.zhWt.childImageSharp.fixed
                        : data.enWt.childImageSharp.fixed
                      : locale === "zh"
                        ? data.zh.childImageSharp.fixed
                        : data.en.childImageSharp.fixed
                  }
                  alt="Zhu Travel"
                />
              </LocalizedLink>
              <Hidden smDown>
                <Typography className={classes.navSubheader}>
                  {translations?.copyright} © {new Date().getFullYear()}
                </Typography>
              </Hidden>
            </Grid>
            {footerNav.map(({ title, links }) => (
              <Grid key={title} item xs={12} sm={6} md={2}>
                <List
                  subheader={
                    <ListSubheader
                      disableSticky
                      disableGutters
                      className={classes.navSubheader}
                    >
                      {title}
                    </ListSubheader>
                  }
                >
                  {links.map(item => (
                    <ListItem
                      key={item.path}
                      dense
                      disableGutters
                      component={LocalizedLink}
                      to={item.path}
                      target={item.external ? "_blank" : ""}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "body2",
                        }}
                      >
                        {item.label}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            ))}
          </Grid>
          <Box>
            <Hidden mdUp>
              <Box mb={1}>
                <Typography className={classes.navSubheader}>
                  {translations?.copyright}
                </Typography>
              </Box>
            </Hidden>
            <Zigzag color={theme.palette.text.primary} />
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default Footer
