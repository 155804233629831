export const hasThisDate = (datesArray, date) => {
  let dates = datesArray.map(item => {
    let newDate = new Date(item)
    newDate.setHours(0, 0, 0, 0)
    newDate = newDate.getTime()
    return newDate
  })
  let dayTime = date.getTime()
  let result = dates.includes(dayTime)
  return result
}

export const dmcPageListFormatter = (list, images) => {
  const actualList = []
  Array.from({ length: Math.ceil(list.length) }).map((_, index) => {
    if (index % 2 === 0) {
      const object = [
        { title: list[index], image: images[index] },
        { title: list[index + 1], image: images[index + 1] },
      ]
      actualList.push(object)
    }
    return null
  })
  return actualList
}

export const objectKeysAdder = (cards, key) => {
  const tempCards = []
  Object.values(cards).map(card => {
    tempCards.push({ [key]: card })
    return null
  })
  return tempCards
}

export const objectKeysRemover = cards => {
  return Object.values(cards).map(card => {
    return Object.values(card)[0]
  })
}