import React from "react"
import "./index.scss"
import { Grid } from "@material-ui/core"
import Img from "gatsby-image"

const DelegationImagesGrid = ({ delegationSectionGridImages }) => {
  return (
    <Grid
      container
      spacing={2}
      style={{ alignItems: "flex-end" }}
      className="delegation-images-grid"
    >
      <Grid item xs={7}>
        <div className="imageWrapper_1">
          <Img fluid={delegationSectionGridImages[0].fluid} className="img" />
        </div>
        <div className="imageWrapper_2">
          <Img fluid={delegationSectionGridImages[1].fluid} className="img" />
        </div>
      </Grid>
      <Grid item xs={5}>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div className="imageWrapper_3">
            <Img
              fluid={delegationSectionGridImages[2].fluid}
              style={{ height: "100%", maxWidth: "100%" }}
              className="img"
            />
          </div>
          <div className="imageWrapper_4">
            <Img
              fluid={delegationSectionGridImages[3].fluid}
              style={{ maxWidth: "100%" }}
              className="img"
            />
          </div>
        </div>
        <div className="imageWrapper_5">
          <Img fluid={delegationSectionGridImages[4].fluid} className="img" />
        </div>
      </Grid>
    </Grid>
  )
}

export default DelegationImagesGrid
